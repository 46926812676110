var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('reactive-base',{attrs:{"app":_vm.elasticSearchCluster,"url":_vm.url,"credentials":_vm.credentials,"type":"_doc"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"color":"blue-grey"},on:{"click":function($event){return _vm.$router.push({
              name: 'KBDrugDetail',
              params: { drugId: 'nuevo' }
            })}}},[_vm._v(" Nuevo "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-plus ")])],1)],1),_c('v-col',{attrs:{"cols":"7"}},[_c('v-combobox',{attrs:{"items":_vm.allFields,"label":"Columnas","multiple":"","chips":""},model:{value:(_vm.selectedFields),callback:function ($$v) {_vm.selectedFields=$$v},expression:"selectedFields"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"items":_vm.pageSizeOptions,"label":"Resultados"},model:{value:(_vm.pageSize),callback:function ($$v) {_vm.pageSize=$$v},expression:"pageSize"}})],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('data-search',{staticClass:" grow",attrs:{"innerClass":{ input: 'reactive-search-input' },"showIcon":false,"URLParams":true,"showClear":false,"filterLabel":"Name","componentId":"SearchSensor","autosuggest":true,"placeholder":"Buscar...","dataField":_vm.visibleTextFields}})],1),_c('ReactiveList',{staticClass:"results",attrs:{"componentId":"result","dataField":_vm.dataField,"sortBy":_vm.sortDirection,"innerClass":{ resultStats: 'result-stat' },"pagination":"","size":parseInt(_vm.pageSize),"URLParams":true,"react":_vm.reactiveListReact,"prevLabel":"<","nextLabel":">","showResultStats":false},scopedSlots:_vm._u([{key:"render",fn:function(ref){
            var loading = ref.loading;
            var error = ref.error;
            var data = ref.data;
return _c('div',{},[(loading)?_c('div',[_vm._v("Fetching Results.")]):(Boolean(error))?_c('div',[_vm._v(" Something went wrong! Error details "+_vm._s(JSON.stringify(error))+" ")]):_c('v-simple-table',[_c('thead',[_c('tr',[_vm._l((_vm.selectedHeaders),function(h){return _c('th',{key:h.value},[(h.value == _vm.sortField)?_c('span',{staticClass:"sorting-field",on:{"click":function($event){_vm.sortAscending = !_vm.sortAscending}}},[_vm._v(_vm._s(h.text)+" "),_c('v-icon',[_vm._v("mdi-chevron-"+_vm._s((_vm.sortAscending && "up") || "down"))])],1):(_vm.isSortable(h.display))?_c('span',{staticClass:"sortable-field",on:{"click":function($event){return _vm.updateSortingField(h)}}},[_vm._v(_vm._s(h.text))]):_c('span',[_vm._v(_vm._s(h.text))])])}),_c('th')],2)]),_c('tbody',_vm._l((data),function(result){return _c('tr',{key:result._id},[_vm._l((_vm.selectedHeaders),function(h){return _c('td',{key:h.value},[(h.display == 'chip')?_c('div',_vm._l((result[h.value]),function(val){return _c('v-chip',{key:val},[_vm._v(_vm._s(val))])}),1):(h.display == 'boolean')?_c('div',[_c('v-icon',{attrs:{"color":(result[h.value] && 'green') || 'red'}},[_vm._v(_vm._s(("mdi-" + ((result[h.value] && "check") || "close"))))])],1):_c('div',[_vm._v(" "+_vm._s(result[h.value])+" ")])])}),_c('td',[_c('v-icon',{on:{"click":function($event){return _vm.$router.push({
                      name: 'KBDrugDetail',
                      params: { drugId: result._id }
                    })}}},[_vm._v("mdi-circle-edit-outline")])],1)],2)}),0)])],1)}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }